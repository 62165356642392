















































import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import { BPopover } from 'bootstrap-vue';
import { Saque } from '@/plugins/api';

@Component
export default class ExcluirAnexo extends Vue {
  @Prop() public readonly item!: IAnexo;
  @Ref() public readonly popover!: BPopover;
  public processing = false;

  get popoverId(): string {
    return `excluir-anexo-${this.item.id}`;
  }

  dismiss(): void {
    this.$root.$emit('bv::hide::popover');
  }

  excluir(): void {
    this.processing = true;
    this.dismiss();
    Saque.excluirAnexos(this.item.id_saque, this.item.id)
      .then(() => {
        this.$emit('change');
        this.$swal({
          icon: 'success',
          title: this.$t('anexo.excluir.sucesso'),
        });
      })
      .catch((err) => {
        this.$swal({
          icon: 'error',
          title: err.response.data,
        });
      })
      .finally(() => {
        this.processing = false;
      });
  }
}
