





































































import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import { Saque } from "@/plugins/api";

@Component({})
export default class ObservacoesList extends Vue {
  @Prop({ default: false }) public readonly deleteBtn!: boolean;
  @Prop() public readonly saque!: number;
  @PropSync('items', { default: [] })
  public readonly observacoes!: IObservacoes[];
  public popover = false;
  public progress = false;
  public observacao = '';

  public get empty(): boolean {
    return (this.observacoes ?? []).length === 0;
  }

  public get observacaoInvalid(): boolean {
    return this.observacao.trim().length <= 0;
  }

  public tooglePopover(): void {
    this.popover = !this.popover;
  }

  public change(): void {
    this.$emit('change');
  }

  public salvarObservacao(): void {
    this.progress = true;
    this.popover = false;
    const params: PostObservacaoRequest = {
      texto: this.observacao,
    };
    Saque.criarObservacao(this.saque, params)
      .then((response) => {
        this.change();
        // this.toastSuccessError(response.success, response.email ?? false);
        this.observacao = '';
      })
      .catch(() => {
        // this.toastSuccessError(false);
      })
      .finally(() => {
        this.progress = false;
      });
  }

}
