





































import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import AnexosItem from './AnexosItem.vue';
import {Api, Auth} from "@/plugins/api";

@Component({
  components: {
    AnexosItem,
  },
})
export default class AnexosGrid extends Vue {
  @Prop({ default: false }) public readonly deleteBtn!: boolean;
  @Prop() public readonly saque!: number;
  @PropSync('items', { default: [] })
  public readonly anexos!: IAnexo[];
  public filePondAnexo = {};
  public files = [];

  mounted(): void {
    this.filePondAnexo = {
      url: Api.baseUrl,
      process: {
        url: `/saques/${this.saque}/anexos`,
        headers: {
          Authorization: Auth.instance.token,
        },
      },
    };
  }

  public get empty(): boolean {
    return (this.anexos ?? []).length === 0;
  }

  public change(): void {
    this.files = [];
    this.$emit('change');
  }
}
