














































import { Component, Vue, Prop } from 'vue-property-decorator';
import ExcluirAnexo from './ExcluirAnexo.vue';
import env from '@/env';

@Component({
  components: {
    ExcluirAnexo,
  },
})
export default class AnexosItem extends Vue {
  @Prop() public readonly item!: IAnexo;
  @Prop({ default: false }) public readonly deleteBtn!: boolean;
  public prefixoUrl = env.VUE_APP_URL_ARQUIVOS;
  public processing = false;

  get url(): string {
    return `${this.prefixoUrl}${this.item.caminho}`;
  }

  get nome(): string {
    return this.item.nome !== '' ? this.item.nome : 'Anexo';
  }

  public change(): void {
    this.$emit('change');
  }
}
